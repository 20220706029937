import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import AgeVerification from './components/main_screens/AgeVerification';
import Login from './components/main_screens/login';
import { base } from './base';
import BigScreen from './components/main_screens/BigScreen';
import SideBigScreen from './components/main_screens/SideBigScreen';
import ReactGA from 'react-ga';


if(process.env.NODE_ENV === "production"){
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView(path) {
    path = path || window.location.pathname;
    if(process.env.NODE_ENV === "production"){
        ReactGA.set({ page: path });
        ReactGA.pageview(path);
    }
}

function AuthenticatedRoute({component: Component, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
                if(authenticated === true){
                    return (
                        <Component {...props} {...rest} />
                    )
                } else {
                    return (
                        <Redirect to={{pathname: '/login', state: {from: props.location}}} />
                    )
                }
            }} />
    )
}

class App extends Component {
  constructor(props) {
        super(props)
        this.setCurrentUser = this.setCurrentUser.bind(this);
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.state = {
            authenticated: !!userEmail,
            variables: {},
            loading: true,
            passedEmail: false
        }
    }

    setCurrentUser() {
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

  componentDidMount() {
    this.getTenantVariables();
  }

  getTenantVariables(){
      let langague = "en"
      let splitWindowLocation = window.location.pathname.split('/') || ""
      if(window.location.pathname.split('/').length > 2){
          langague = splitWindowLocation[2]
      } else if(window.location.pathname.split('/').length > 1){
          langague = splitWindowLocation[1]
      }
      this.loadLanguage(langague)
        this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
            context: this,
            then(variables){
                document.title = variables.pageTitle || "Polling";
                if(!variables.primaryColor){
                    variables.primaryColor = 'transparent'
                }

                if(!variables.secondaryColor){
                    variables.secondaryColor = 'transparent'
                }

                if(!variables.buttonOutlineColor){
                    variables.buttonOutlineColor = 'transparent'
                }
                this.setState({
                    authenticated: false,
                    variables: variables,
                    loading: false,
                    langague: langague
                })
            }
        });
    }

    checkForAgeGate(tenantVariables){
        const variable_age_boolean = tenantVariables.collectBirthday || false;
        if(!variable_age_boolean){
            return true
        }
        const formBirthday = tenantVariables.formBirthday || false;
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        let user_age = sessionStorage.getItem('verifiedAge') || false;
        if(isNaN(parseInt(user_age))){
            user_age = false
        } else {
            user_age = parseInt(user_age, 10)
        }
        return (user_age && user_age >= variable_age) || formBirthday;
    }

  async loadLanguage(langauge){
      let languagesConstants;
      if(langauge){
          try {
              languagesConstants = await import("./constants/languages/"+langauge+".js");
          } catch(e) {
              langauge = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
              languagesConstants = await import("./constants/languages/"+langauge+".js");
          }
      } else {
          langauge = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
          languagesConstants = await import("./constants/languages/"+langauge+".js");
      }
      this.setState({
          lanaguageConstants: languagesConstants
      })
  }

  componentWillUnmount() {
    base.removeBinding(this.tenantVariablesRef);
  }

    setPassedEmail(isSet=true){
        this.setState({
            passedEmail: isSet
        })
    }

  render() {
    if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading}/>
        )
    }
      let redirectUrl = `/login`;
      if(this.state.langague){
          redirectUrl += ("/" + this.state.langague);
      }
    return (
      <div style={{ margin: "0 auto"}}>
        <BrowserRouter>
            <div className="main-content">
                <div className="workspace">
                  <Switch>
                    <Route
                      strict
                      path="/login"
                      render={(props) => {
                        return <Login authenticated={this.state.authenticated} loadPage={() => logPageView()} setCurrentUser={this.setCurrentUser} variables={this.state.variables} setPassed={(setPassed)=>this.setPassedEmail(setPassed)} stringConstants={this.state.lanaguageConstants} checkForAgeGate={this.checkForAgeGate} {...props} />
                      }}
                    />
                    <Route
                      path="/bigscreen"
                      render={(props) => {
                        return <BigScreen variables={this.state.variables} {...props} />
                      }}
                    />
                    <Route
                      path="/sidebigscreen"
                      render={(props) => {
                        return <SideBigScreen variables={this.state.variables}  {...props} />
                      }}
                    />

                      <Route
                          strict
                          path="/age_gate"
                          render={(props) => {
                              return <AgeVerification loadPage={() => logPageView()} stringConstants={this.state.lanaguageConstants} variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />
                          }}
                      />

                      <AuthenticatedRoute
                          exact
                          path="/"
                          authenticated={this.state.authenticated}
                          variables={this.state.variables}
                          passedEmail={this.state.passedEmail}
                          loadPage={() => logPageView()}
                          checkForAgeGate={this.checkForAgeGate}
                          stringConstants={this.state.lanaguageConstants}
                          component={MainApp} />

                    {/*<Route*/}
                    {/*  path="/"*/}
                    {/*  render={(props) => {*/}
                    {/*    return <MainApp passedEmail={this.state.passedEmail} stringConstants={this.state.lanaguageConstants} variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />*/}
                    {/*  }}*/}
                    {/*/>*/}

                      <Redirect to={redirectUrl}/>
                  </Switch>
                </div>
            </div>
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
